<template>
  <div style="margin: 20px">
    <el-button type="success" style="margin-bottom: 10px" @click="create">Создать</el-button>
    <v-table :fetch="fetchData" name="viceGovernor" :columns="columns"/>
  </div>
</template>

<script>
import VTable from '@/components/VTable'
import { getViceGovernors } from '@/api/dictionaries'
export default {
  components: {
    VTable
  },
  data () {
    return {

    }
  },
  computed: {
    columns () {
      return [
        {
          field: 'is_deleted',
          filter (value) {
            return value ? 'Нет' : 'Да'
          },
          items: [
            {
              title: 'Действующий',
              id: false
            },
            {
              title: 'Недействующий',
              id: true
            }
          ]
        },
        {
          field: 'place',
          filterable: false
        }
      ]
    }
  },
  methods: {
    fetchData: (...args) => getViceGovernors(...args),
    create () {
      this.$router.push({ name: this.$route.name.replace('list', 'create') })
    }
  }
}
</script>

<style scoped>

</style>
